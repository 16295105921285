
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, actionsModule, actionTypesModule } from '@/store/modules/store-accessor'
import _ from 'lodash'
import { TimeUtil } from '@/helpers/TimeUtil'
import { isDecimalNumber, getOptimalTextColor, isNumber, timeChargedUnitLabels, calculateTimeChargingCost } from "@/helpers/data";
import dayjs from 'dayjs';
import AssigneeCombo from '@/components/Combobox/AssigneeCombo.vue';

@Component({
  components: {
    AssigneeCombo,
  },
})
export default class EditActionModal extends Vue {
  @Prop()
  payload!: any;

  createdAtMenu = false;
  createAtTimeMenu = false;
  
  actionModel = {} as any
  payloadActionModel = {} as any
  isEditingAction = false;

  costRule:any =[(val: any) => val !== '' && val >= 0 || this.$i18n.t("cost_required"),
  (val: any) => val >= this.minActionAmount || this.$i18n.t("single_action_cost_rule")]
  userRule= [
    (val: any) => !!val || this.$i18n.t("user_required"),
  ]
  typeRule= [
    (val: any) => !!val || this.$i18n.t("action_required"),
  ]

  notesRule = [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("form_notes_length_rule");
      else return true;
    },
  ]

  unitOptionRule = [
    (val: any) => (val !== null && val !== "" && val >= 0) || this.$i18n.t("requiredRule"),
  ]

  unitRule = [
    (val: any) => (val !== null && val !== "" && val >= 0) || this.$i18n.t("requiredRule"),
  ]

  unitQuantityRule = [
    (val: any) => ((!!val || val == '') && val.text.length > 0) || this.$i18n.t("unitOptionRule"),
  ]

  async mounted() {
    await commonModule.getTimeChargedUnitCombo();
    this.payloadActionModel = _.cloneDeep(this.payload.action)
    this.payloadActionModel.action_type_id = this.findActionType(this.payload.action);
    this.payloadActionModel.assignee_id = this.getAssigneeId(this.payload.action.assignee_id);
    this.payloadActionModel.created_at_date = dayjs(this.payload.action.created_at).format('YYYY-MM-DD');
    this.payloadActionModel.created_at_time = dayjs(this.payload.action.created_at).format('HH:mm');
    // this.payloadActionModel.unit = this.timeChargedUnitCombo.find((i: any) => i.unit === this.payloadActionModel.unit);

    this.actionModel = _.cloneDeep(this.payloadActionModel)

    commonModule.initSnackbar({});
  }

  updateAssignee(assignee: any) {
    if(assignee) {
      this.actionModel.assignee_id = assignee;
    }
  }

  async hideModal () {
    commonModule.hideModal();
  }

  findActionType(action: any) {
    let combo: any = actionTypesModule.actionTypesCombo;
    const foundType = combo.find((item: any) => item.id === action.action_type_id);
    if(foundType) {
      return { text: foundType.text, color: foundType.color, cost: foundType.cost, id: foundType.id, is_time_charged: foundType?.is_time_charged, unit: foundType?.unit };
    } else {
      const disabledAction = { text: this.payload.action.type_name, color: this.payload.action.type_color, cost: 0, id: this.payload.action.action_type_id , is_time_charged: this.payload.action?.is_time_charged,  unit: this.payload.action?.unit };
      this.actionsCombo.push(disabledAction)
      return disabledAction;
    }
  }

  get action() {
    return this.actionModel
  }

  get minActionAmount() {
    return this.payload.action.cost - this.payload.job.balance
  }

  get editDisabled() {
    return _.isEqual(this.actionModel, this.payloadActionModel)
  }

  get timeChargedUnitCombo() {
    return commonModule.timeChargedUnitCombo;
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  get assigneeIdCombo () {
      return commonModule.personnelCombo;
  }

  get actionsCombo () {
      return actionTypesModule.actionTypesCombo;
  }

  getAssigneeId(name: string) {
    const combo: any = commonModule.personnelPartnersCombo;
    const foundItem = combo.find((item: any) => item.name === name);
    if(foundItem) {
      return { name: foundItem.name, id: foundItem.id };
    } else if(!foundItem && this.payload.action.assignee_id) {
      return { name: this.payload.action.assignee_id }
    } else {
      return ''
    }
  }

  entryTextColor(action: any) {
    switch (action.entry_type) {
      case 'action':
        return getOptimalTextColor(action.type_color)
      case 'payment':
        return 'darkGreen'
      default:
        return 'black';
    }
  }

  async doEditAction () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isEditingAction = true;
      let actionObj = _.cloneDeep(this.actionModel);
      await actionsModule.editAction(actionObj);
      this.isEditingAction = false;
      this.hideModal();
    }
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }

  onlyNumbers(evt: any) {
      isNumber(evt);
  }

  calculateTimeChargingCost(action: Record<string, any>) {
    return calculateTimeChargingCost(action);
  }

  timeChargedUnitLabels(unit: Record<string, any>) {
    return timeChargedUnitLabels(unit?.unit);
  }

};
