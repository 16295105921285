
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  commonModule,
  appointmentsModule,
  actionTypesModule,
  itemsModule,
  companyModule,
  dashboardModule,
} from "@/store/modules/store-accessor";
import { getOptimalTextColor } from "@/helpers/data";
import AssigneeUserCombo from "@/components/Combobox/AssigneeUserCombo.vue";
import AssigneeMultiUserCombo from "@/components/Combobox/AssigneeMultiUserCombo.vue";

@Component({
  components: {
    AssigneeUserCombo,
    AssigneeMultiUserCombo,
  },
})
export default class AddAppointmentModal extends Vue {
  @Prop()
  payload!: any;

  isAddingAppointment = false;

  menu3 = false;
  menu4 = false;
  loadingInstallations = true;
  loadingPersonnel = true;
  loadingInstallationPrimaryItems = true;
  disableInstallationPrimaryItems = true;

  payloadAssignee = {} as any; // for calendar page
  primaryInstallationItemsCombo = [] as any;

  jobModel = {
    type_id: { text: "", color: "", cost: 0, id: 0 },
    assignee_id: this.payload.assignee_id
      ? { name: this.payload.assignee_id.name, id: this.payload.assignee_id.id }
      : "",
    assignee_ids: [] as any[],
    appointment_is_confirmed: true,
    appointment_status_id: 2,
    scheduled_start: this.payload.scheduled_start,
    scheduled_start_time: this.payload.scheduled_start_time,
    duration: this.durationCombo[1] || "",
    appointment_notes: this.payload.notes || "",
    installation_id: this.payload.installation_id,
    task_id: this.payload.task_id,
    installation_item_id: {},
    description: "",
  };

  scheduledDateRule = [
    (val: any) => !!val || this.$i18n.t("scheduled_day_required"),
  ];

  userRule = [(val: any) => !!val || this.$i18n.t("user_required")];

  scheduledTimeRule = [
    (val: any) => !!val || this.$i18n.t("scheduled_time_required"),
  ];

  durationRule = [(val: any) => !!val || this.$i18n.t("duration_required")];
  typeRule = [
    (val: any) => (!!val && val.text != "") || this.$i18n.t("action_required"),
  ];
  installationRule = [
    (val: any) => !!val || this.$i18n.t("installation_required"),
  ];
  notesRule = [
    (val: any) => {
      if (val != null)
        return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ];
  descriptionRule = [
    (val: any) => val.length <= 80 || this.$i18n.t("jobDescriptionLengthRule"),
  ];

  get installationPrimaryItems() {
    return itemsModule.installationPrimaryItems;
  }

  get disablePrimaryItems() {
    return (
      this.loadingInstallationPrimaryItems ||
      this.installationPrimaryItems.length === 0
    );
  }

  get durationCombo() {
    return commonModule.durationCombo;
  }

  get actionsCombo() {
    return actionTypesModule.actionTypesCombo;
  }

  get installationsCombo() {
    return commonModule.installationsCombo;
  }

  get isLoadingInstallations() {
    return this.loadingInstallations;
  }

  get isLoadingPersonnel() {
    return this.loadingPersonnel;
  }

  get isLoadingInstallationPrimaryItems() {
    return this.loadingInstallationPrimaryItems;
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  installationText(item: any) {
    let installation_arr = item.is_business
      ? [
          item.tag_id.split("_").pop(),
          `${item.address ? item.address : item.area}`,
          item.description && this.installationDescriptionIsEnabled
            ? item.description
            : "",
          `${item.entity_name} (${item.client_name})`,
          item.phone,
        ]
      : [
          item.tag_id.split("_").pop(),
          `${item.address ? item.address : item.area}`,
          item.description && this.installationDescriptionIsEnabled
            ? item.description
            : "",
          item.client_name,
          item.phone,
        ];

    return installation_arr.filter(Boolean).join(" | ");
  }

  async setActionTypeFromId(action_type_id: any) {
    const foundType = actionTypesModule.actionTypesCombo.find(
      (item: any) => item.id === action_type_id
    );
    if (foundType) {
      this.jobModel.type_id = {
        text: foundType.text,
        color: foundType.color,
        cost: foundType.cost,
        id: foundType.id,
      } as any;
    }
  }

  async hideModal() {
    commonModule.hideModal();
    itemsModule.setInstallationPrimaryItems([]);
  }

  async created() {
    await actionTypesModule.getActionTypesCombo();
    await this.setActionTypeFromId(this.payload.action_type_id);
  }

  async beforeMount() {
    // If user adds a new appointment from installations page, the payload will not be undefined
    if (typeof this.payload.installation !== "undefined") {
      await itemsModule.getInstallationPrimaryInventoryItems(
        this.payload.installation.info.id
      );
      this.loadingInstallationPrimaryItems = false;
      this.disableInstallationPrimaryItems = false;

      this.jobModel.installation_id = this.payload.installation.info.id;
      if (this.payload.installation_item_id) {
        this.jobModel.installation_item_id = this.itemName({
          primary_items: this.payload.job_item,
          id: this.payload.installation_item_id,
          item_type_text: this.payload.item_type_text,
          item_make: this.payload.item_make,
          item_model: this.payload.item_model,
          item_serial_no: this.payload.item_serial_no,
        });
      } else if (
        this.$route.name === "Calendar" &&
        !this.payload.installation_item_id
      ) {
        this.jobModel.installation_item_id = { primary_items: "", id: 0 };
      } else {
        (this.jobModel.installation_item_id as any) =
          this.defaultInstallationItem();
      }
    } else {
      // Add appointment from the calendar page
      this.loadingInstallations = true;
      await commonModule.getInstallationsCombo();
      this.loadingInstallations = false;

      // Stop the loader but keep it disabled until the user selects an installation
      this.loadingInstallationPrimaryItems = false;
      this.disableInstallationPrimaryItems = true;
    }
  }

  async mounted() {
    // Pass the assignee in the complete task with appointment and calendar page
    if (
      this.payload.assignee_id &&
      typeof this.payload.assignee_id === "string"
    ) {
      // task
      this.payloadAssignee = this.getAssigneeId(this.payload.assignee_id);
    } else if (this.payload.assignee_id && this.payload.assignee_id.name) {
      // calendar
      this.payloadAssignee = this.getAssigneeId(this.payload.assignee_id.name);
    } else {
      this.payloadAssignee = "";
    }

    commonModule.initSnackbar({});
    await commonModule.getActionCombo();
    await commonModule.getDurationCombo();
    await commonModule.getPersonnelCombo();
    await actionTypesModule.getActionTypesCombo();
  }

  defaultInstallationItem() {
    if (
      this.installationPrimaryItems &&
      this.installationPrimaryItems.length === 1
    ) {
      return this.installationPrimaryItems[0];
    }
    return { primary_items: "", id: 0 };
  }

  updateAssignee(assignees: any[]) {
    if (assignees.length > 0) {
      this.jobModel.assignee_id = assignees[0];
    }
    this.jobModel.assignee_ids = assignees;
  }

  getAssigneeId(name: string) {
    const combo: any = commonModule.personnelCombo;
    const foundItem = combo.find((item: any) => item.name === name);
    if (foundItem) {
      return [{ name: foundItem.name, id: foundItem.id }];
    } else {
      return [];
    }
  }
  getInstallationId(selectedInstallationId: string) {
    if (selectedInstallationId) {
      this.loadingInstallationPrimaryItems = true;
      this.disableInstallationPrimaryItems = true;
      itemsModule.getInstallationPrimaryInventoryItems(selectedInstallationId);
      this.loadingInstallationPrimaryItems = false;
      this.disableInstallationPrimaryItems = false;
    } else {
      itemsModule.setInstallationPrimaryItems([]);
    }
  }

  itemName(obj: any) {
    this.primaryInstallationItemsCombo = this.installationPrimaryItems;
    const foundItem = this.primaryInstallationItemsCombo.find(
      (item: any) =>
        item.primary_items[0] == obj.primary_items && item.id === obj.id
    );
    if (foundItem) {
      return foundItem;
    } else {
      if (obj.id) {
        let item_hidden_id = (Math.random() + 1).toString(36).substring(7);
        this.installationPrimaryItems.push({
          ...obj,
          disabled: true,
          hidden_id: item_hidden_id,
        });
        return {
          ...obj,
          disabled: true,
          hidden_id: item_hidden_id,
        };
      } else {
        return { primary_items: "", id: 0 };
      }
    }
  }

  async doAdd() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isAddingAppointment = true;
      // Check if user removed the selected assignee_id from the combo in the calendar
      if (
        typeof this.jobModel.assignee_id === "undefined" ||
        this.jobModel.assignee_id == "" ||
        this.jobModel.assignee_id == null
      ) {
        this.jobModel.assignee_id = "";
      }
      await appointmentsModule.createAppointment(this.jobModel);

      if (this.$route.name === "Dashboard") {
        await dashboardModule.getDashboardAppointmentsAction(
          dashboardModule.filters
        );
        await dashboardModule.getDashboardAppointmentsBarsAction(
          dashboardModule.filters
        );
      } else if (this.$route.name === "Calendar") {
        appointmentsModule.getCalendarAppointmentsAction(
          appointmentsModule.filters
        );
      }

      this.hideModal();
      // Update calendar as soon as a new appointment is added
      // Needed for whenever a new appointment is added from the calendar page
      appointmentsModule.getCalendarAppointmentsAction(
        appointmentsModule.filters
      );
    }
  }

  get installationDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)
      ?.installation_description;
  }

  get jobDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }
}
