import { ActionType } from "@/types";
import {
  VuexModule,
  Module,
  Action,
} from "vuex-module-decorators";
import ActionsApi from "@/api/actions-api";
import { installationsModule, partnersModule } from '@/store/modules/store-accessor'
import dayjs from "dayjs";

export interface ActionState {
  allActions: ActionType[];
}

@Module({ name: "actions" })
export default class ActionModule extends VuexModule implements ActionState {
  public allActions: ActionType[] = [];

  @Action
  async deleteAction(data: Record<string, any>) {
    const actionsApi = new ActionsApi()
    await actionsApi.deleteAction(data.id)
    await installationsModule.getInstallationInfo(data.installation_id);
  }

  @Action
  async editAction(action: Record<string, any>) {
    const actionsApi = new ActionsApi()
    if(action.created_at_date && action.created_at_time) {
      action.created_at = dayjs(action.created_at_date + ' ' +  action.created_at_time);
      delete action.created_at_date;
      delete action.created_at_time;
    }

    if(!action.action_type_id.is_time_charged) {
      action.unit_cost = null;
      (action.unit ??= {}).unit = null;
      action.unit_quantity = null;
    }

    await actionsApi.updateAction(
      action.id, 
      { cost: action.cost, notes: action.notes, assignee_id: action.assignee_id.id,action_type_id: action.action_type_id.id, created_at: action.created_at,
      unit: action.unit?.unit, unit_cost: action?.unit_cost, unit_quantity: action?.unit_quantity }
      );
    await installationsModule.getInstallationInfo(action.installation_id);

    if(action.partner_id) {
      await partnersModule.getPartnersInfo(action.partner_id);
    }
  }

}
