
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  commonModule,
  jobsModule,
  itemsModule,
  companyModule,
} from "@/store/modules/store-accessor";
import { TimeUtil } from "@/helpers/TimeUtil";
import { isDecimalNumber } from "@/helpers/data";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

@Component
export default class EditJobModal extends Vue {
  @Prop()
  payload!: any;

  isEditingJob = false;

  dateMenu = false;
  scheduledTimeMenu = false;
  scheduled_start_time = this.formatTime(this.payload.job.created_at);
  loadingInstallationPrimaryItems = true;

  jobModel = {} as any;
  primaryInstallationItemsCombo = [] as any;

  costRule: any = [];
  scheduledTimeRule = [
    (val: any) => !!val || this.$i18n.t("scheduled_time_required"),
  ];

  jobDescriptionRule = [
    (val: any) =>
      val == null ||
      val?.text?.length <= 80 ||
      val?.length <= 80 ||
      this.$i18n.t("installationDescriptionLengthRule"),
  ];

  get costDisabled() {
    return this.jobModel.cost_disabled == 1 ? true : false;
  }

  get installationPrimaryItems() {
    return itemsModule.installationPrimaryItems;
  }

  get isLoadingInstallationPrimaryItems() {
    return this.loadingInstallationPrimaryItems;
  }

  get disablePrimaryItems() {
    return (
      this.loadingInstallationPrimaryItems ||
      this.installationPrimaryItems.length === 0
    );
  }

  @Watch("jobModel.cost")
  onActualCostChange(val: string, oldVal: string) {
    this.costRule = [];
    if (!val) {
      this.costRule.push(
        (val: any) => !!val || this.$i18n.t("estimated_cost_required")
      );
    }
    this.costRule.push(
      (val: any) =>
        val >= parseInt(this.payload.job.cost) ||
        val >= parseInt(this.payload.job.amount_paid) ||
        this.$i18n.t("actionCostRule")
    );
  }

  async hideModal() {
    commonModule.hideModal();
  }

  async beforeMount() {
    await itemsModule.getInstallationPrimaryInventoryItems(
      this.payload.job.installation_id
    );

    this.jobModel.created_at = this.formatDate(this.payload.job.created_at);
    this.jobModel.cost = this.payload.job.cost;
    this.jobModel.cost_disabled = this.payload.job.cost_disabled;
    this.jobModel.is_complete = this.payload.job.is_complete;
    this.jobModel.notes = this.payload.job.notes || "";
    this.jobModel.id = this.payload.job.id;
    this.jobModel.installation_id = this.payload.job.installation_id;
    this.jobModel.partner_id = this.payload.job.partner_id || "";
    this.jobModel.installation_item_id = this.itemName({
      primary_items: this.payload.job.job_item,
      id: this.payload.job.installation_item_id,
      item_type_text: this.payload.job.item_type_text,
      item_make: this.payload.job.item_make,
      item_model: this.payload.job.item_model,
      item_serial_no: this.payload.job.item_serial_no,
    });
    this.jobModel.description = this.payload.job.description || "";
  }

  async mounted() {
    commonModule.initSnackbar({});
    await commonModule.getPersonnelCombo();
    await commonModule.getActionCombo();
    await commonModule.getDurationCombo();
    this.loadingInstallationPrimaryItems = false;
  }

  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }

  formatDate(date: Date): string {
    return TimeUtil.formatDateOnly(date);
  }

  formatTime(date: Date): string {
    return TimeUtil.formatTimeOnly(date);
  }

  itemName(obj: any) {
    this.primaryInstallationItemsCombo = this.installationPrimaryItems;
    const foundItem = this.primaryInstallationItemsCombo.find(
      (item: any) =>
        item.primary_items[0] == obj.primary_items && item.id === obj.id
    );
    if (foundItem) {
      return foundItem;
    } else {
      if (obj.id) {
        let item_hidden_id = (Math.random() + 1).toString(36).substring(7);
        this.installationPrimaryItems.push({
          ...obj,
          disabled: true,
          hidden_id: item_hidden_id,
        });
        return {
          ...obj,
          disabled: true,
          hidden_id: item_hidden_id,
        };
      } else {
        return { primary_items: "", id: 0 };
      }
    }
  }

  async doEditJob() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isEditingJob = true;
      this.jobModel.created_at = dayjs(
        this.jobModel.created_at + " " + this.scheduled_start_time
      )
        .utc()
        .format("DD MMM YYYY HH:mm:ss");
      await jobsModule.editJob(this.jobModel);
      this.jobModel.created_at = this.formatDate(this.payload.job.created_at); // Format created_at again because of RangeError warning
      jobsModule.getJobs(jobsModule.filters);
      this.hideModal();
    }
  }
  get descriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }
}
